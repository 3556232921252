import { createSearchParams } from 'react-router-dom'
import { hasValue, parseOptionalIntNanSafe } from '@digital-magic/ts-common-utils'
import { isEnumValue } from '@model/utils'
import { UserLanguageEnum } from '@model/language'
import { publicRoutes } from '@constants/routes'
import { UrlString, VehicleId } from '@api/endpoints'
import { VehicleSelectionMode } from './types'

export const isVehicleSelectionMode = isEnumValue(VehicleSelectionMode)

export const parseSelectionMode = (selectionMode: string | undefined): VehicleSelectionMode | undefined =>
  hasValue(selectionMode) && isVehicleSelectionMode(selectionMode) ? (selectionMode as VehicleSelectionMode) : undefined

// eslint-disable-next-line functional/prefer-tacit
export const parseVehicleId = (vehicleId: string | undefined): VehicleId | undefined =>
  parseOptionalIntNanSafe(vehicleId)

export const urlWithQueryParamVehicleId = (url: UrlString, vehicleId: VehicleId): UrlString =>
  `${url}?${createSearchParams({ vehicleId: vehicleId.toString() }).toString()}`

export const urlCatalogWithSelectionMode = (selectionMode: VehicleSelectionMode, lang: UserLanguageEnum): UrlString =>
  `${publicRoutes(lang).Catalog}?selectionMode=${selectionMode}`

const routeBySelectionMode = (selectionMode: VehicleSelectionMode, lang: UserLanguageEnum): UrlString => {
  switch (selectionMode) {
    case VehicleSelectionMode.Installment:
      return publicRoutes(lang).Installment
    case VehicleSelectionMode.Exchange:
      return publicRoutes(lang).Exchange
  }
}

export const urlBySelectionMode = (
  selectionMode: VehicleSelectionMode,
  vehicleId: VehicleId,
  lang: UserLanguageEnum
): UrlString => urlWithQueryParamVehicleId(routeBySelectionMode(selectionMode, lang), vehicleId)

export const isNumericValueGreaterThanZero = (value: number | undefined): boolean => {
  return value !== undefined && value > 0
}

export const anyNumericValueGreaterThanZero = (...values: Array<number | undefined>): boolean => {
  return values.some(isNumericValueGreaterThanZero)
}
